
import Vue from "vue";
import Api from "@/mixins/api";
import showDialogs from "@/mixins/showDialogs";

import BatchEntrySearchList, {
  BatchEntrySearchListCls
} from "@/components/batchEntry/search/BatchEntrySearchList.vue";
import BatchEntryDialog from "@/components/batchEntry/BatchEntryDialog.vue";
import { TokuiGroup } from "@/models/transaction/TokuiGroup";
import { JyutyuMeisai } from "@/models/transaction/JyutyuMeisai";
import {
  BatchEntryTableHeaderType,
  BatchEntryTableType
} from "@/components/batchEntry/BatchEntryTable.vue";
import { BatchEntryFormCls } from "@/components/batchEntry/BatchEntryForm.vue";
import { ReceivedOrderBatchEntryResponse } from "@/models/batchEntry/ReceivedOrderBatchEntryResponse";
import { JyutyuHedder } from "@/models/transaction/JyutyuHedder";
import { BatchEntryInsertRequest } from "@/models/batchEntry/BatchEntryInsertRequest";

const api = new Api();
const dialog = new showDialogs();

const defaultHeaders: BatchEntryTableHeaderType[] = [
  {
    text: "産地",
    value: "santiNm",
    type: "text"
  },
  {
    text: "品名",
    value: "shohinNm",
    type: "text"
  },
  {
    text: "規格",
    value: "kikakuNm",
    type: "text"
  },
  {
    text: "単価",
    value: "tanka",
    type: "number",
    disabled: true
  },
  {
    text: "担当者",
    value: "tantoNm",
    type: "text",
    disabled: true
  }
];

export default Vue.extend({
  name: "ReceivedOrderBatchEntry",
  components: { BatchEntrySearchList, BatchEntryDialog },
  props: {},
  data() {
    return {
      title: "一括実数入力",
      isOpen: false,
      isEdit: false,
      dialogForm: new BatchEntryFormCls({ denpyoNo: 0 }),
      selectLists: [] as BatchEntrySearchListCls[],
      clientLists: [] as TokuiGroup[],
      formatLists: [] as JyutyuMeisai[],
      table: [] as BatchEntryTableType[]
    };
  },
  computed: {
    tableHeaders: {
      get(): BatchEntryTableHeaderType[] {
        const h = [...defaultHeaders];
        const client = this.clientLists;
        for (const c of client) {
          h.push({
            text: String(c.abbreviation),
            value: c.tokuiId,
            type: "number"
          });
        }
        return h;
      }
    }
  },
  methods: {
    async onAdd() {
      console.log(api.Paths.approvalList);
      const denpyoNo = await api.$get<number>(api.Paths.jyutyuDenpyoNo);
      this.dialogForm = new BatchEntryFormCls({ denpyoNo: denpyoNo });
      this.table = [];
      this.isEdit = false;
      this.isOpen = true;
    },
    async onEdit(item: BatchEntrySearchListCls) {
      //ダイアログフォームセット
      this.dialogForm = new BatchEntryFormCls({
        denpyoNo: item.denpyoNo,
        denpyoDate: item.denpyoDate,
        client: {
          tokuiId: item.tokuiId,
          groupId: item.tokuiGroup
        },
        formatId: String(item.formatId),
        kaiukeId: item.kaiukeId,
        biko: item.biko
      });

      //テーブルデータセット
      const result = await api.$post<ReceivedOrderBatchEntryResponse>(
        api.Paths.batchEntry.getReceivedOrderMeisai,
        { denpyoNo: item.denpyoNo }
      );

      const table: BatchEntryTableType[] = result.meisai.map(e => ({
        gyo: e.gyo,
        santiNm: e.santiNm,
        shohinNm: e.shohinNm,
        kikakuNm: e.kikakuNm,
        tanka: e.tanka,
        tantoNm: e.tantoNm,
        isAddRow: false
      }));
      for (const f of table) {
        const targetHattyusuu = result.jissuuList.filter(e => e.gyo === f.gyo);
        for (const c of targetHattyusuu) {
          f[c.tokuiId] = c.jissuu;
        }
      }
      this.table = table;

      this.isEdit = true;
      this.isOpen = true;
    },
    async onFormat() {
      if (this.formatLists.length !== 0) {
        const client = this.clientLists;
        const format: BatchEntryTableType[] = this.formatLists.map(e => ({
          gyo: e.gyo,
          santiNm: e.santiNm,
          shohinNm: e.shohinNm,
          kikakuNm: e.kikakuNm,
          tanka: e.tanka,
          tantoNm: e.tantoNm,
          isAddRow: false
        }));
        for (const f of format) {
          for (const c of client) {
            f[c.tokuiId] = null;
          }
        }
        this.table = format;
      }
    },
    async onSubmit() {
      try {
        this.$store.commit("loading/set", true);
        const header = new JyutyuHedder({
          denpyoNo: this.dialogForm.denpyoNo,
          denpyoDate: this.dialogForm.denpyoDate
            ? new Date(this.dialogForm.denpyoDate)
            : null,
          tokuiId: this.dialogForm.client?.tokuiId,
          tokuiGroup: this.dialogForm.client?.groupId,
          indexId: this.clientLists.length,
          biko: this.dialogForm.biko,
          formatId: Number(this.dialogForm.formatId),
          kaiukeId: this.dialogForm.kaiukeId
        });

        const meisaiList: JyutyuMeisai[] = [];

        for (const [i, t] of this.table.entries()) {
          for (const [j, c] of this.clientLists.entries()) {
            const meisai = new JyutyuMeisai({
              denpyoNo: this.dialogForm.denpyoNo + j,
              tokuiId: c.tokuiId,
              gyo: i + 1,
              jissuu: t[c.tokuiId],
              santiNm: t.santiNm,
              kikakuNm: t.kikakuNm,
              shohinNm: t.shohinNm,
              tantoNm: t.tantoNm,
              tanka: t.tanka
            });
            meisaiList.push(meisai);
          }
        }

        const request = new BatchEntryInsertRequest({
          header: header,
          meisai: meisaiList
        });

        if (this.isEdit) {
          await api.$post(api.Paths.batchEntry.bulkUpdate, request);
        } else {
          await api.$post(api.Paths.batchEntry.bulkInsert, request);
        }

        const ref = this.$refs.batchEntrySearchList as any;
        await ref.onSelect();
        console.log(ref);
        //await this.onSelect();
        this.isOpen = false;
      } catch (e) {
        dialog.$error("一括実数の登録に失敗しました", "エラー");
      } finally {
        this.$store.commit("loading/set", false);
      }
    }
  }
});
